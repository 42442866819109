import { ArtWork } from "../../types/ArtWork";
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { LinkIcon } from "../LinkIcon";
import React, { useState } from "react";
import Slide from "@mui/material/Slide";
import styled from "styled-components";
import Stack from "@mui/material/Stack";
import { StyledDescriptionShort } from "../../styled/StyledDescriptionShort";

type StyledContainerProps =
  | {
      textColor: string;
    }
  | any;

const StyledGridContainer = styled(Grid)<StyledContainerProps>`
  color: ${(props) => props.textColor};
  //background-color: #ffffff36;
  // overflow: hidden;
`;

const StyledDescriptionLong = styled(Typography)`
  overflow-y: auto;
  overflow-x: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 10; /* Limit to 7 lines */
  -webkit-box-orient: vertical;
  white-space: pre-line;
  @media (max-width: 600px) {
    // -webkit-line-clamp: 7; /* Limit to 7 lines */
  }
`;

type StyledButtonProps =
  | {
      target?: string;
    }
  | any;

const StyledSmallButton = styled(Button)<StyledButtonProps>`
  font-size: 8pt !important;

  @media (max-width: 920px) {
    color: white !important;
    background: black;
  }
`;

export function ArtWorkDetails({
  artWork,
  direction,
  textColor,
  minDisplay,
}: {
  artWork: ArtWork;
  direction: "left" | "right" | "up" | "down";
  textColor?: string;
  minDisplay?: boolean;
}) {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  return (
    <Grid
      item
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      xs={textColor === undefined ? 6 : 12}
      sm={5}
      wrap="nowrap"
      spacing={2}
    >
      <Slide in={true} timeout={1000} direction={direction}>
        <StyledGridContainer
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={textColor === undefined ? 6 : 12}
          sm={5}
          wrap="nowrap"
          spacing={2}
          textColor={textColor}
        >
          <Grid
            item
            style={window.innerWidth <= 920 ? { paddingLeft: "10%" } : {}}
          >
            <Typography
              variant={window.innerWidth >= 920 ? "h2" : "h3"}
              fontWeight={500}
            >
              {artWork.name}
            </Typography>
          </Grid>
          {minDisplay ? (
            <Grid
              item
              xs={6}
              sm={11}
              wrap={"nowrap"}
              style={window.innerWidth <= 920 ? { paddingLeft: "10%" } : {}}
            >
              {/*   <Typography variant={"body1"}>{artWork.description}</Typography>*/}
              {showFullDescription ? (
                <StyledDescriptionLong variant={"body1"} fontWeight={500}>
                  {artWork.description}
                </StyledDescriptionLong>
              ) : (
                <StyledDescriptionShort variant={"body1"} fontWeight={500}>
                  {artWork.description}
                </StyledDescriptionShort>
              )}
              {artWork.description?.length >= 200 ? (
                <Button size="small" onClick={toggleDescription}>
                  {showFullDescription ? "Read Less" : "Read More"}
                </Button>
              ) : (
                <></>
              )}
            </Grid>
          ) : (
            <Grid item xs={2}>
              <StyledDescriptionLong variant={"body1"} fontWeight={500}>
                {artWork.description}
              </StyledDescriptionLong>
            </Grid>
          )}
          <Grid
            item
            container
            xs={2}
            direction={"column"}
            alignItems={"center"}
            spacing={2}
          >
            {minDisplay ? (
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  href={`/artwork/${artWork.id}`}
                  target="_blank"
                  color={"secondary"}
                >
                  Visit Art Work
                </Button>
              </Grid>
            ) : (
              ""
            )}

            <Grid item>
              <Stack
                sx={{ pt: 4 }}
                direction="row"
                spacing={2}
                justifyContent="center"
              >
                {artWork.links?.map(({ URL, label, caption }) => (
                  <StyledSmallButton
                    size="small"
                    href={URL}
                    target="_blank"
                    variant={"contained"}
                    startIcon={
                      <LinkIcon label={label} size={"10pt"} color="white" />
                    }
                  >
                    {caption}
                  </StyledSmallButton>
                ))}
              </Stack>
            </Grid>
          </Grid>
        </StyledGridContainer>
      </Slide>
    </Grid>
  );
}
