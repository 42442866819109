import { ArtWork } from "../../types/ArtWork";
import { Grid } from "@mui/material";
import { ArtWorkDetails } from "./ArtWorkDetails";
import { ArtWorkImage } from "./ArtWorkImage";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getArtWorkById } from "../../services/ArtWorksService";
import { ProductsExplorer } from "../Product/ProductsExplorer";

export function ArtWorkView({
  artWork,
  index,
  minView,
}: {
  index?: number;
  artWork?: ArtWork;
  minView?: boolean;
}) {
  const { id } = useParams();

  let loadedArtWork;
  if (id && !artWork) {
    loadedArtWork = getArtWorkById(id);
  }

  const [viewedArtWork] = useState<ArtWork | undefined>(
    artWork ?? loadedArtWork,
  );

  function getMainView() {
    if (!viewedArtWork || index === undefined) {
      return <div />;
    }

    if (window.innerWidth >= 960) {
      return (
        <Grid
          item
          container
          direction={"row"}
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          spacing={2}
        >
          {index % 2 === 0 ? (
            <ArtWorkDetails
              artWork={viewedArtWork}
              direction="right"
              minDisplay={!Boolean(id)}
            />
          ) : (
            <ArtWorkImage imageUrl={viewedArtWork.imageUrl} direction="right" />
          )}
          {index % 2 === 0 ? (
            <ArtWorkImage imageUrl={viewedArtWork.imageUrl} direction="left" />
          ) : (
            <ArtWorkDetails
              artWork={viewedArtWork}
              minDisplay={!Boolean(id)}
              direction="left"
            />
          )}
        </Grid>
      );
    } else {
      return (
        <Grid container direction="column" spacing={2}>
          {/*  <ArtWorkImage imageUrl={artWork.imageUrl} direction="up" />

            <ArtWorkDetails artWork={artWork} direction="up" />
          </Grid>
          */}
          <ArtWorkImage
            direction={index % 2 === 0 ? "left" : "right"}
            imageUrl={viewedArtWork.imageUrl}
          />

          <ArtWorkDetails
            minDisplay={true}
            artWork={viewedArtWork}
            direction={"up"}
          />
        </Grid>
      );
    }
  }

  return (
    <>
      {getMainView()}
      <ProductsExplorer artWork={viewedArtWork} minView={minView} />
    </>
  );
}
