import React from "react";
import { Grid, Typography } from "@mui/material";
import { ArtWorkImage } from "./ArtWork/ArtWorkImage";

const Originals = () => {
  return (
    <Grid container direction={"column"} spacing={3}>
      <ArtWorkImage
        direction={"up"}
        imageUrl={"/homepage.jpg"}
        full={true}
      ></ArtWorkImage>
      <Grid item>
        <Typography variant="h5">Coming soon... </Typography>
      </Grid>
    </Grid>
  );
};

export default Originals;
