import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import Navigation from "./components/Navigation";
import { About } from "./components/About";
import Album from "./components/Album";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AppFooter } from "./components/AppFooter";
import Originals from "./components/Originals";
import Contact from "./components/Contact";
import { ArtWorkPage } from "./components/ArtWork/ArkWorkPage/ArtWorkPage";

// Define your custom colors
const primaryMainColor = "#000000"; // Replace with your primary color hex code
//const secondaryMainColor = "#5dddd8"; // Replace with your secondary color hex code

// Create a theme instance
const appTheme = createTheme({
  palette: {
    primary: {
      main: primaryMainColor,
    },
    /*
    secondary: {
      main: secondaryMainColor,
    },*/
  },
  components: {
    // Override styles for all MUI buttons
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0, // Set border radius to 0
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <Box className="App">
        <Router>
          <div>
            <Navigation />
            <main>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/about" element={<About />} />
                <Route path="/gallery" element={<Album />} />
                <Route path="/originals" element={<Originals />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/artwork/:id" element={<ArtWorkPage />} />
                {/* Other routes as needed */}
              </Routes>
            </main>
          </div>
          <AppFooter />
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default App;
