import styled from "styled-components";
import { Grid } from "@mui/material";
import Slide from "@mui/material/Slide";
import React, { ReactNode } from "react";

interface StyledCardMediaProps {
  imageUrl: string;
  full?: boolean;
}

export const StyledCardMedia = styled.div<StyledCardMediaProps>`
  background-position-y: ${(props) => (props.full ? "44% !important" : "0")};
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  // width: 100%; // Changed to 100% for better responsiveness
  width: ${(props) => (props.full ? "100%" : "100%")};
  height: ${(props) => (props.full ? "132vh" : "105vh")};
  background-repeat: no-repeat;
  background-position: center;
  max-width: 100%;
  @media (max-width: 600px) {
    height: ${(props) =>
      props.full ? "49vh" : "50vh"}; // Adjust height for mobile screens
    background-position-y: 74%;
    width: ${(props) => (props.full ? "100vh" : "100vh")};
  }
`;

export function ArtWorkImage({
  imageUrl,
  direction,
  children,
  full,
}: {
  imageUrl: string;
  direction: "left" | "right" | "up" | "down";
  children?: ReactNode;
  full?: boolean;
}) {
  return (
    <Grid
      item
      xs={10}
      sm={7}
      container
      alignItems={"center"}
      justifyContent={"center"}
      direction={"row"}
    >
      <Slide in={true} direction={direction} timeout={1500}>
        <Grid item xs={12} sm={12}>
          <StyledCardMedia imageUrl={imageUrl} full={full}>
            {children}
          </StyledCardMedia>
        </Grid>
      </Slide>
    </Grid>
  );
}
