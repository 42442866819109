import { ArtWork, Product } from "../../types/ArtWork";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Carousel from "react-material-ui-carousel";
import styled from "styled-components";
import { ProductCard } from "./ProductCard";

const ProductsExplorerContainer = styled("div")`
  background: #5dddd8ba;
  padding: 3% 4% 0% 4%;
  max-width: 100%;
  @media (max-width: 920px) {
    /* padding: 6% 0% 6% 0%;*/
    padding: 49px;
  }
`;

export function ProductsExplorer({
  artWork,
  minView,
}: {
  artWork: ArtWork | undefined;
  minView?: boolean;
}) {
  // Function to group products into subarrays of 3
  const groupProducts = (products: Product[]) => {
    let grouped = [];
    const stepSize = window.innerWidth >= 920 ? 3 : 2;

    for (let i = 0; i < products.length; i += stepSize) {
      if (i + stepSize > products.length) {
        grouped.push(
          products.slice(products.length - stepSize, products.length),
        );
      } else {
        grouped.push(products.slice(i, i + stepSize));
      }
    }
    return grouped;
  };

  const groupedProducts = groupProducts(artWork?.products || []);
  const [randomGroupNumber] = useState(
    minView ? Math.floor(Math.random() * groupedProducts.length) : 0,
  );
  if (!artWork?.products?.length) {
    return <></>;
  }

  return (
    <ProductsExplorerContainer>
      {window.innerWidth <= 920 ? (
        <Grid
          container
          spacing={4}
          direction={"row"}
          alignItems={"space-around"}
        >
          {(minView
            ? groupedProducts[randomGroupNumber]
            : artWork.products
          ).map((product, index) => (
            <ProductCard key={index} product={product} />
          ))}
        </Grid>
      ) : (
        <Carousel interval={12000} animation="slide">
          {groupedProducts.map((group, index) => (
            <Grid container spacing={4} key={index}>
              {group.map((product, idx) => (
                <ProductCard key={idx} product={product} />
              ))}
            </Grid>
          ))}
        </Carousel>
      )}
    </ProductsExplorerContainer>
  );
}
