import React, { useCallback, useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import { artistUser, artWorks } from "../data/data";
import { ArtWork } from "../types/ArtWork";
import { ArtWorkView } from "./ArtWork/ArtWorkView";
import { ArtWorkImage } from "./ArtWork/ArtWorkImage";
import styled from "styled-components";

const StyledContainerMainLogoGrid = styled(Grid)`
  height: 100vh;
  /*width: 100%;
*/
  @media (max-width: 600px) {
    height: 100%;
    width: 21vh;
    max-width: 400px;
    font-size: 18pt !important;
  }
`;

const StyledSiteName = styled(Typography)`
  // font-family: "Nothing You Could Do", cursive;
`;
const LandingPage = () => {
  const [shuffledSubset] = useState<ArtWork[]>(
    [...artWorks].sort(() => 0.5 - Math.random()),
  );

  const [displayedArtworks, setDisplayedArtworks] = useState<ArtWork[]>(
    shuffledSubset.slice(0, 3),
  );
  const [artworkIndex, setArtworkIndex] = useState(3);

  const loadMoreArtworks = useCallback(() => {
    const scrollPosition =
      window.innerHeight + document.documentElement.scrollTop;
    const offsetHeight = document.documentElement.offsetHeight;

    // Check if the user is within 100px of the bottom of the page
    if (
      scrollPosition + 100 < offsetHeight ||
      displayedArtworks.length === artWorks.length
    )
      return;

    // Load more artworks
    const moreArtworks = shuffledSubset.slice(artworkIndex, artworkIndex + 3); // Load 3 more artworks
    console.log(artworkIndex, moreArtworks);
    setDisplayedArtworks((currentArtworks) => [
      ...currentArtworks,
      ...moreArtworks,
    ]);
    setArtworkIndex(artworkIndex + 3);
  }, [artworkIndex, displayedArtworks.length, shuffledSubset]);

  useEffect(() => {
    window.addEventListener("scroll", loadMoreArtworks);
    return () => window.removeEventListener("scroll", loadMoreArtworks);
  }, [loadMoreArtworks]);

  /*useEffect(() => {*/
  // Shuffle and select a subset of artworks
  /* const shuffledSubset = [...artWorks].sort(() => 0.5 - Math.random());

  const newDisplayed = shuffledSubset.slice(0, 3); // Adjust the number for the subset size

  setDisplayedArtworks(newDisplayed);
  setShuffledSubset(shuffledSubset);*/
  /*});*/

  return (
    <Grid container direction={"column"} spacing={3}>
      <ArtWorkImage direction={"up"} imageUrl={"/homepage.jpg"} full={true}>
        <StyledContainerMainLogoGrid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          wrap="nowrap"
          spacing={2}
        >
          <Grid item>
            <StyledSiteName
              variant={window.innerWidth >= 920 ? "h2" : "h5"}
              fontWeight={800}
              color={"white"}
            >
              {artistUser.siteName}
            </StyledSiteName>
          </Grid>
        </StyledContainerMainLogoGrid>
      </ArtWorkImage>
      <br />
      {displayedArtworks.map((artWork, index) => {
        return <ArtWorkView index={index} artWork={artWork} minView={true} />;
      })}
      {/*</Box>*/}
    </Grid>
  );
};

export default LandingPage;
