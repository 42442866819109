import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { artistUser } from "../data/data";
import { StyledCardMedia } from "./ArtWork/ArtWorkImage";

const StyledGridItem = styled(Grid)`
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const PreLineTypography = styled(Typography)`
  white-space: pre-line;
`;

export function About() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <StyledGridItem item xs={12} sm={6}>
        <Box padding={2}>
          <Typography variant="h2">{artistUser.displayName}</Typography>
          <PreLineTypography variant="body1">
            {artistUser.aboutContent}
          </PreLineTypography>
        </Box>
      </StyledGridItem>
      <StyledGridItem item xs={12} sm={6}>
        <StyledCardMedia
          imageUrl={artistUser.aboutImageURL}
          full={true}
        ></StyledCardMedia>
      </StyledGridItem>
    </Grid>
  );
}
