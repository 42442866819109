import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CollectionsIcon from "@mui/icons-material/Collections"; // For OpenSea
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { FaBehance, FaSpotify } from "react-icons/fa";

const getIcon = (
  label: string,
  size: string = "15pt",
  color: string = "rgba(0, 0, 0, 0.54)", // Black and white color style
) => {
  const iconStyle = {
    color,
    fontSize: size,
    margin: "10px",
  };
  switch (label.toLowerCase()) {
    case "facebook":
      return <FacebookIcon sx={iconStyle} />;
    case "instagram":
      return <InstagramIcon sx={iconStyle} />;
    case "twitter":
      return <TwitterIcon sx={iconStyle} />;
    case "linkedin":
      return <LinkedInIcon sx={iconStyle} />;
    case "opensea":
      return <CollectionsIcon sx={iconStyle} />;
    case "redbubble":
      return <ShoppingCartIcon sx={iconStyle} />;
    case "spotify":
      //return <Spotify sx={iconStyle} />;
      return <FaSpotify color="white" style={iconStyle} />;
    case "behance":
      return <FaBehance color={"gray"} style={iconStyle} />;
    default:
      return null;
  }
};

export function LinkIcon(props: {
  label: string;
  size?: string;
  color?: string;
}) {
  return <>{getIcon(props.label, props.size, props.color)}</>;
}
