import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { StyledDescriptionShort } from "../../styled/StyledDescriptionShort";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import React from "react";
import { LinkIcon } from "../LinkIcon";

export function ProductCard(props: { product: any }) {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardMedia
          component="div"
          sx={{ pt: "100%" }}
          image={props.product.imageURL}
        />
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography gutterBottom variant="h5" component="h2">
            {props.product.name}
          </Typography>
          <StyledDescriptionShort>
            {props.product.description}
          </StyledDescriptionShort>
        </CardContent>
        <CardActions>
          <a
            href={props.product.URL}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={"view"}
          >
            <Button
              size="small"
              variant={"contained"}
              startIcon={
                <LinkIcon label={"redbubble"} color={"white"} size={"3"} />
              }
            >
              get yours
            </Button>
          </a>
          <Typography variant={"body2"}>Delivery Included</Typography>
        </CardActions>
      </Card>
    </Grid>
  );
}
