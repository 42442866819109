import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Copyright } from "./Copyright";
import React from "react";
import { artistUser } from "../data/data";
import { LinkIcon } from "./LinkIcon"; // For Redbubble

export function AppFooter() {
  return (
    <Box component="footer" sx={{ bgcolor: "background.paper", p: 6 }}>
      {/*      <Typography variant="h6" align="center" gutterBottom>
        Connect with Us
      </Typography>*/}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        {artistUser.links.map(({ URL, label }) => (
          <Box key={label} sx={{ mx: 1 }}>
            <Typography
              variant="subtitle1"
              align="center"
              color="text.secondary"
              component="p"
            >
              <a
                href={URL}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={label}
              >
                <LinkIcon label={label} />
              </a>
            </Typography>
          </Box>
        ))}
      </Box>

      <Typography
        variant="subtitle2"
        align="center"
        color="text.secondary"
        component="p"
      >
        All Rights Reserved
      </Typography>
      <Copyright />
    </Box>
  );
}
