import React from "react";
import "./ArtWorkPage.scss";
import { useParams } from "react-router-dom";
import { getArtWorkById } from "../../../services/ArtWorksService";
import { Grid } from "@mui/material";
import { ProductsExplorer } from "../../Product/ProductsExplorer";
import { ArtWorkImage } from "../ArtWorkImage";
import { ArtWorkDetails } from "../ArtWorkDetails";

export function ArtWorkPage() {
  const { id } = useParams();

  const artWork = getArtWorkById(id || "");

  if (!artWork) {
    return <div />;
  }

  return (
    <>
      {/*<div className="art-work-page">
        <div className="description">
          <p>{artWork.description}</p>
          <div className="links">
            {artWork.links?.map((l) => (
              <>
                <a href={l.URL} target="_blank" rel="noreferrer">
                  <Button>{l.caption}</Button>
                </a>
              </>
            ))}
          </div>
        </div>
        <div className="carousel">
          <Carousel interval={10000}>
            {[
              { URL: artWork.imageUrl, imageURL: artWork.imageUrl },
              ...(artWork.products || []),
            ].map((product) => (
              <a href={product.URL} target="_blank" rel="noreferrer">
                <img
                  style={{ maxWidth: "100%" }}
                  src={product.imageURL}
                  alt=""
                />
              </a>
            ))}
          </Carousel>
        </div>
      </div>*/}
      <Grid
        container
        direction={window.innerWidth <= 920 ? "column" : "row"}
        spacing={2}
      >
        <ArtWorkImage imageUrl={artWork.imageUrl} direction="up" />

        <ArtWorkDetails artWork={artWork} direction="up" />
      </Grid>
      <ProductsExplorer artWork={artWork} />
    </>
  );
}
