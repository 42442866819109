import styled from "styled-components";
import { Typography } from "@mui/material";

export const StyledDescriptionShort = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
  @media (max-width: 600px) {
    -webkit-line-clamp: 3; /* Limit to 7 lines */
  }
  white-space: pre-line;
`;
