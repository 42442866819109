import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { artistUser } from "../data/data";

const pages = [
  {
    link: "/",
    pageName: "Home",
  },
  {
    link: "/gallery",
    pageName: "Gallery",
  },
  {
    link: "/originals",
    pageName: "Originals",
  },
  {
    link: "/about",
    pageName: "About the Artist",
  },
  {
    link: "/contact",
    pageName: "Contact",
  },
];

const Navigation = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // todo: extract navigation items into consts and share between mobile and none
  return (
    <AppBar position="static" color="transparent">
      <Toolbar>
        <Typography variant="h5" style={{ flexGrow: 1 }}>
          {/* Exhibit Elite*/}
          {artistUser.siteName}
        </Typography>

        {/* Display the hamburger menu icon on smaller screens */}
        {window.innerWidth < 960 ? (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
        ) : null}

        {/* Display the navigation links on larger screens */}
        {window.innerWidth >= 960 ? (
          <div>
            {pages.map((page) => (
              <Button color="inherit" component={Link} to={page.link}>
                {page.pageName}
              </Button>
            ))}
          </div>
        ) : null}

        {/* Hamburger menu for smaller screens */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {pages.map((page) => (
            <MenuItem component={Link} to={page.link} onClick={handleMenuClose}>
              {page.pageName}
            </MenuItem>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
