import { ArtWork, ArtWorkCollection } from "../types/ArtWork";

export const artistUser = {
  siteName: "Shani Rahamim Art",

  displayName: "Shani Rahamim",
  // aboutImageURL: "https://lh3.googleusercontent.com/pw/ABLVV85OxG5itelvSObwr2C17UaGjEn8wFLcCDqPFoL8zp1ZYIvZE8EceDlVCbXOvBIqgr9ZnubksBIl0nuGbasECs93FDgawIu_eC3ous7oAgEEI1LlfUcqvfUjNrKtTXBEJk3MpfbWyLbau9XzO4laEg5C2xVjUhZPia0Jb8KhDt54fQNOXLKosJsbKdztu4at9q-dk-jfAbJFIkO5f3FD4u7QnD7UoTWiwsgzaPOAfDvVHHuE6Jp80SdXFp8bm-jbahod_b2kICVZGNVbQXwaRajgPewBzFnPeOq7PvWeMDvs4Uzn95E28HFG8ZTMaLafzdXesslg_dtAy-EtUTvT38vYL5Rr7KI0FNiwhp6YD4fRxStyBKaSmjd5vjdCL7epOmq4rBRyqzNvdeeKxcebMlOdGW2NRzEEe8xXJkxy4t_g92601XqIMghTO2j-zSUL-7jR03r3jV1Tr_ow8UwPxwVXGI9CTtfwE2xeGUdQ0PlAJp6ySmRCtb4rEq1rKouN226uvTi6edkLy8XqFMGJdMuMzq_979d54kKFsydR6NUC8Y1D_vgdy1rznVh75J3T9hnCMuCg-cq2HZTlBSWYa4BJOjiUUalbT43n__LHoLOY_1NiQSvkVyD7m48QK0PPDHbsAD4eeuP112Spc4zVS2pRzC6_emCJefBvUFJUjTJzZjL7Uj0wtzBfu4zOCevaFF3032RJDkChU0zmisAkq5O1kxTj5BfkaRvi3ukOPXZOLCJrxo24736W5ei_IQRQBD0Q58ZuXH2f0eDIZYJMg9ONQoJWt4GPBqfHcpbpQ-ULzOWHM3KKidlUhmUuSFboYp3nzyVovxxG2k2tf7UrEZv9FMmUcFzl9OHcD8Ri91I4iXcTpNeFgi5i4o7UB09WmAkC9Aw=w586-h879-s-no-gm?authuser=0",
  aboutImageURL: "/shani-portait.jpg",
  aboutContent:
    "Shani Rahamim is an artist whose passion for painting has been evident since she was four years old. Her artistic journey took root under the tutelage of various instructors, most notably at the esteemed Monart Art Center in Ashdod. Shani's talent has blossomed over the years, culminating in the creation of more than 60 canvases, many of which now grace the walls of private homes.\n" +
    "\n" +
    'In 2019, Shani embraced the realm of digital art, a move that not only deepened her love for painting but also broadened her creative scope. Among her digital accomplishments is the impressive cover art collection for Dan Schachter\'s album "Perceptions," where each track is represented by a distinctive artwork. Additionally, she has developed the "Morning Routine Collection," an extensive series of 1600 unique items, each minted as NFTs on OpenSea.\n' +
    "\n" +
    "Shani's works are accessible through several platforms, with Redbubble being a prominent one. Residing in Tel Aviv, she finds inspiration in her everyday surroundings and experiences, sharing her life with her partner and their cherished dog, Sissi.\n" +
    "\n" +
    "Regarded as an artist still at the commencement of a promising path, Shani Rahamim continues to explore and push the boundaries of her art, demonstrating a relentless pursuit of creativity and innovation.",
  galleryDesc:
    "Step into a diverse gallery where each artwork is a testament to my journey from traditional painting to the digital art frontier. My collection ranges from tranquil landscapes captured on canvas to dynamic digital compositions that redefine art. Each piece in this gallery is not just a painting; it's an intimate part of my story, crafted with passion and precision",
  contactMeDesc:
    "Whether you're interested in commissioning a piece, inquiring about my work, or simply saying hello, I'm just a message away. Contact me to start a conversation, discuss an art project, or follow my artistic journey. Let's create connections that inspire.",
  siteURL: "http://shanirarts.com",
  links: [
    { URL: "https://www.facebook.com/colordoodles", label: "facebook" },
    { URL: "https://www.instagram.com/shanir_arts", label: "instagram" },
    { URL: "https://www.twitter.com/Miss_Rahamim", label: "twitter" },
    { URL: "https://www.linkedin.com/in/shani-rahamim", label: "linkedin" },
    { URL: "https://opensea.io/Shanir", label: "opensea" },
    { URL: "https://shanirahamim.redbubble.com", label: "redbubble" },
    { URL: "https://www.behance.net/shanirahamim1", label: "behance" },
  ],
};

export const collections: Array<ArtWorkCollection> = [
  {
    name: "Perceptions",
    description:
      "In this artistic collection, Shani Rahamm beautifully captures the essence of a unique creative process alongside Dan Schachter. While Shani paints, Dan describes, creating a harmonious interplay of visuals and words that breathe life into each artwork. Each piece in this series is more than just a painting; it's a narrative journey, echoing the emotions, visions, and sensations that surfaced during the creation of Dan's album 'Perceptions of Reality'. Every artwork is closely linked to a specific song, with its colors and shapes vividly portraying aspects of Dan's personality and universally relatable emotions. The Handpan, Dan's instrument of choice, adds a meditative depth to the collection, inviting viewers to embark on a profound exploration of the soul. This collaboration between music and visual art by Shani and Dan is designed to resonate deeply with the audience, making each piece a unique and touching experience.",
    links: [
      {
        label: "spotify",
        URL: "https://open.spotify.com/album/7qRgASyjO03ONAc8qF5fs5?si=iFbjTLAQS2CHNVbfFqWJjA",
      },
    ],
    artWorks: [
      {
        id: "ad97da46-92e0-4bf7-87fa-37b1e01e877c",
        name: "Perceptions Map",
        imageUrl:
          "https://mir-s3-cdn-cf.behance.net/project_modules/fs/0a8495188699749.659fe6242f3a7.jpg",
        description:
          '"The world we have created", says Shani. inside this painting you are able to find a reference to each of the songs. can you spot them? This piece was painted on canvas as well, and it was the gift Shani gave to Dan Once the night of the premiere, and was presented during the night.',
      },
      {
        id: "edb2ef43-1330-41cc-9070-15aa1d0a178e",
        name: "Day & Night - intro",
        imageUrl:
          "https://mir-s3-cdn-cf.behance.net/project_modules/fs/a90154188699749.659fe6242baca.jpg",
        description:
          "Day and Night (intro)\n" +
          "(intro) is a captivating painting by Shani Rahamim from the Perceptions album collection by the Dan Schachter Ensemble. Each artwork embodies a distinct melody, capturing the emotions and visuals Dan Schachter experienced during their creation. Released on Nov 22, immerse yourself in the full album on various platforms",
        links: [
          {
            label: "spotify",
            URL: "https://open.spotify.com/track/2LjVlT1koQUtqxuj7JQoFR?si=0d457577533547e7",
            caption: "listen to the song on spotify",
          },
        ],
        products: [
          {
            URL: "https://www.redbubble.com/i/photographic-print/Day-and-Night-intro-by-shanirahamim/147586552.Y8UA9?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
            name: "Day and Night (intro) Photographic Print",
            imageURL:
              "https://ih1.redbubble.net/image.5070013597.6552/cpp,x_large,lustre,product,750x1000.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/acrylic-block/Day-and-Night-intro-by-shanirahamim/147586552.P5BX3?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
            name: "Day and Night (intro) Acrylic Block",
            imageURL:
              "https://ih1.redbubble.net/image.5070013605.6552/abp,4x4,x750-pad,750x1000,f8f8f8.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/clock/Day-and-Night-intro-by-shanirahamim/147586552.7PFC0?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
            name: "Day and Night (intro) Clock",
            imageURL:
              "https://ih1.redbubble.net/image.5070013612.6552/clkc,white,white,1000x1250-pad,750x1000,f8f8f8.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/coasters/Day-and-Night-intro-by-shanirahamim/147586552.E5I3N?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
            name: "Day and Night(intro) Coasters (Set of 4)",
            imageURL:
              "https://ih1.redbubble.net/image.5070013605.6552/ur,coaster_pack_4_flatlay,wide_portrait,750x1000.1.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/canvas-print/Day-and-Night-intro-by-shanirahamim/147586552.T8P6B?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
            name: "Day and Night (intro) Canvas Print",
            imageURL:
              "https://ih1.redbubble.net/image.5070013597.6552/ccp,x_large,product,750x1000.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/laptop-case/Day-and-Night-intro-by-shanirahamim/147586552.81RRT?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
            name: "Day and Night (intro) Laptop Sleeve",
            imageURL:
              "https://ih1.redbubble.net/image.5070013603.6552/ls,12inch,x750-pad,750x1000,f8f8f8.jpg",
          },
        ],
      },
      {
        id: "3a0a6b0d-5762-41ab-98c0-998b4e4c02ac",
        name: "Suninja",
        imageUrl:
          "https://mir-s3-cdn-cf.behance.net/project_modules/fs/729f31188699749.659fe6242d630.jpg",
        description:
          "'Suninja' is a captivating painting by Shani Rahamim from the Perceptions album collection by the Dan Schachter Ensemble. Each artwork embodies a distinct melody, capturing the emotions and visuals Dan Schachter experienced during their creation. Released on Nov 22, immerse yourself in the full album on various platforms",
        links: [
          {
            label: "spotify",
            URL: "https://open.spotify.com/track/0CspZLbPo4psyUgfvCz6xa?si=2b20693a02ee42c0",
            caption: "listen to the song on spotify",
          },
        ],
        products: [
          {
            URL: "https://www.redbubble.com/i/coasters/Suninja-by-shanirahamim/147586188.E5I3N?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
            name: "Suninja Coasters (Set of 4)",
            imageURL:
              "https://ih1.redbubble.net/image.5070000294.6188/ur,coaster_pack_4_flatlay,wide_portrait,750x1000.1.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/throw-pillow/Suninja-by-shanirahamim/147586188.5X2YF?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
            name: "Suninja Throw Pillow",
            imageURL:
              "https://ih1.redbubble.net/image.5070000306.6188/throwpillow,small,750x1000-bg,f8f8f8.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/clock/Suninja-by-shanirahamim/147586188.7PFC0?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
            name: "Suninja Clock",
            imageURL:
              "https://ih1.redbubble.net/image.5070000327.6188/clkc,white,white,1000x1250-pad,750x1000,f8f8f8.jpg",
          },
        ],
      },
      {
        id: "07bfa015-4557-402b-9ad7-ee6e6a3a0938",
        name: "Soaring Hawk",
        imageUrl:
          "https://mir-s3-cdn-cf.behance.net/project_modules/fs/fb0ddf188699749.659fe6242a120.jpg",
        description:
          "'Soaring Hawk' is a captivating painting by Shani Rahamim from the Perceptions album collection by the Dan Schachter Ensemble. Each artwork embodies a distinct melody, capturing the emotions and visuals Dan Schachter experienced during their creation. Released on Nov 22, immerse yourself in the full album on various platforms",
        links: [
          {
            label: "spotify",
            URL: "https://open.spotify.com/track/0HomC63iqOe5PCgEBPtakj?si=90086e370c774956",
            caption: "listen to the song right now!",
          },
        ],
        products: [
          {
            URL: "https://www.redbubble.com/i/laptop-skin/Soaring-Hawk-by-shanirahamim/147586332.6EA4Y",
            name: "Soaring Hawk Laptop Skin",
            imageURL:
              "https://ih1.redbubble.net/image.5070005602.6332/pd,x500,macbook_air_13-pad,750x1000,f8f8f8.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/iphone-skin/Soaring-Hawk-by-shanirahamim/147586332.62NBT",
            name: "SoaringHawk iPhone Skin",
            imageURL:
              "https://ih1.redbubble.net/image.5070005561.6332/mwo,x1000,iphone_6_skin-pad,750x1000,f8f8f8.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/canvas-print/Soaring-Hawk-by-shanirahamim/147586332.5Y5V7",
            name: "Soaring Hawk Canvas Print",
            imageURL:
              "https://ih1.redbubble.net/image.5070005570.6332/ccp,x_large,product,750x1000.webp",
          },
          {
            URL: "https://www.redbubble.com/i/metal-print/Soaring-Hawk-by-shanirahamim/147586332.0JXQP",
            name: "Soaring Hawk Metal Print",
            imageURL:
              "https://ih1.redbubble.net/image.5070005570.6332/cmp,x_large,gloss,tall_portrait,750x1000,study.webp",
          },
          {
            URL: "https://www.redbubble.com/i/mug/Soaring-Hawk-by-shanirahamim/147586332.9Q0AD",
            name: "Soaring Hawk Coffee Mug",
            imageURL:
              "https://ih1.redbubble.net/image.5070005597.6332/ur,mug_lifestyle,tall_portrait,750x1000.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/magnet/Soaring-Hawk-by-shanirahamim/147586332.TBCTK",
            name: "Soaring Hawk Magnet",
            imageURL:
              "https://ih1.redbubble.net/image.5070005737.6332/mo,small,fridge_close,tall_portrait,750x1000.2.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/sticker/Soaring-Hawk-by-shanirahamim/147586332.EJUG5",
            name: "Soaring Hawk Sticker",
            imageURL:
              "https://ih1.redbubble.net/image.5070005737.6332/sss,small,product,750x1000.jpg",
          },
        ],
      },
      {
        id: "303b127e-f3c9-49b2-861a-1454ec83b127",
        name: "Ancient Prophecy",
        imageUrl:
          "https://mir-s3-cdn-cf.behance.net/project_modules/fs/7600d5188699749.659fe6242e3e7.jpg",

        description:
          "'Ancient Prophecy' is a captivating painting by Shani Rahamim from the Perceptions album collection by the Dan Schachter Ensemble. Each artwork embodies a distinct melody, capturing the emotions and visuals Dan Shachter experienced during their creation. Released on Nov 22, immerse yourself in the full album on various platforms\",",
        links: [
          {
            label: "spotify",
            URL: "https://open.spotify.com/track/1c021OCjWb7yANoafd3ljg?si=8fe87edc25cb4728",
            caption: "listen to the song on spotify",
          },
        ],
        products: [
          {
            imageURL:
              "https://ih1.redbubble.net/image.5070022054.5195/ur,mouse_pad_medium_lifestyle_office,wide_portrait,750x1000.webp",
            URL: "https://www.redbubble.com/i/mouse-pad/Ancient-Prophecy-by-shanirahamim/147585195.NXCXM",
            name: "Ancient Prophecy Mouse Pad",
          },
          {
            URL: "https://www.redbubble.com/i/framed-print/Ancient-Prophecy-by-shanirahamim/147585195.L0LHG?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
            name: "Ancient Prophecy Framed Art Print",
            imageURL:
              "https://ih1.redbubble.net/image.5069963234.5195/cfp,large,black,off_white,box20,product,750x1000.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/art-print/Ancient-Prophecy-by-shanirahamim/147585195.A4XVI",
            name: "Ancient Prophecy Art Print",
            imageURL:
              "https://ih1.redbubble.net/image.5069963234.5195/carp,large,product,750x1000.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/mouse-pad/Ancient-Prophecy-by-shanirahamim/147585195.NXCXM",
            name: "Ancient Prophecy Mouse Pad", // todo
            imageURL:
              "https://ih1.redbubble.net/image.5070022054.5195/ur,mouse_pad_medium_flatlay_prop,wide_portrait,750x1000.jpg",
          },
        ],
      },
      {
        id: "52d9ec9a-62b0-42f0-ab20-0f421a77208b",
        name: "Epiphany",
        imageUrl:
          "https://mir-s3-cdn-cf.behance.net/project_modules/fs/bd20fc188699749.659fe62429288.jpg",

        description:
          "'Epiphany' is a captivating painting by Shani Rahamim from the Perceptions album collection by the Dan Schachter Ensemble. Each artwork embodies a distinct melody, capturing the emotions and visuals Dan Shachter experienced during their creation. Released on Nov 22, immerse yourself in the full album on various platforms\",",
        links: [
          {
            label: "spotify",
            URL: "https://open.spotify.com/track/5BMpWHqyBGndXOwfp4cBZ4?si=dc8568874eb84740",
            caption: "listen to the song on spotify",
          },
        ],
        products: [
          {
            URL: "https://www.redbubble.com/i/clock/Epiphany-by-shanirahamim/147586814.7PFC0",
            name: "Epiphany Clock",
            imageURL:
              "https://ih1.redbubble.net/image.5070023267.6814/clkc,white,white,1000x1250-pad,750x1000,f8f8f8.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/metal-print/Epiphany-by-shanirahamim/147586814.0FJ8W",
            name: "Epiphany Metal Print",
            imageURL:
              "https://ih1.redbubble.net/image.5070023245.6814/cmp,x_large,gloss,tall_portrait,750x1000,study.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/poster/Epiphany-by-shanirahamim/147586814.LVTDI",
            name: "Epiphany Poster",
            imageURL:
              "https://ih1.redbubble.net/image.5070023245.6814/cposter,small,product,750x1000.2.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/pouch/Epiphany-by-shanirahamim/147586814.APSXX",
            name: "Epiphany Zipper Pouch",
            imageURL:
              "https://ih1.redbubble.net/image.5070023248.6814/pr,315x215,750x1000-bg,f8f8f8.jpg",
          },
        ],
      },
      {
        id: "438571ab-e662-4651-acc7-4fbdaaa84a5a",
        name: "Waves Of False Reality",
        imageUrl:
          "https://mir-s3-cdn-cf.behance.net/project_modules/fs/4d79d5188699749.659fe6242790c.jpg",
        description:
          "'Waves Of False Reality' is a captivating painting by Shani Rahamim from the Perceptions album collection by the Dan Schachter Ensemble. Each artwork embodies a distinct melody, capturing the emotions and visuals Dan Shachter experienced during their creation. Released on Nov 22, immerse yourself in the full album on various platforms",
        links: [
          {
            label: "spotify",
            URL: "https://open.spotify.com/track/28so2ntTtlXA9uiBlXDv4u?si=ba5bda45d38344d2",
            caption: "listen to the song on spotify",
          },
        ],
        products: [
          {
            URL: "https://www.redbubble.com/i/metal-print/Waves-Of-False-Reality-by-shanirahamim/147586418.0FJ8W?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
            name: "Waves Of False Reality Metal Print",
            imageURL:
              "https://ih1.redbubble.net/image.5070008736.6418/cmp,x_large,gloss,tall_portrait,750x1000,study.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/framed-print/Waves-Of-False-Reality-by-shanirahamim/147586418.AJ1A3",
            name: "Waves Of False Reality Framed Art Print",
            imageURL:
              "https://ih1.redbubble.net/image.5070008736.6418/cfp,small,black,off_white,box20,product,750x1000.jpg",
          },
          /* {
            URL: "https://www.redbubble.com/i/photographic-print/Waves-Of-False-Reality-by-shanirahamim/147586418.6Q0TX",
            name: "Waves Of False Reality Photographic Print",
            imageURL:
              "https://ih1.redbubble.net/image.5070008736.6418/cpp,small,lustre,product,750x1000.jpg",
          },*/
          {
            URL: "https://www.redbubble.com/i/drawstring-bag/Waves-Of-False-Reality-by-shanirahamim/147586418.YT4RK",
            name: "Waves Of False Reality Drawstring Bag",
            imageURL:
              "https://ih1.redbubble.net/image.5070025398.6418/drawstring_bag,x750-pad,750x1000,f8f8f8.jpg",
          },
        ],
      },
      {
        id: "b5d53f88-eaec-4196-827a-d2fd956ca67c",
        name: "Forgotten Understanding",
        imageUrl:
          "https://mir-s3-cdn-cf.behance.net/project_modules/fs/aed1ff188699749.659fe6242adfe.jpg",
        description:
          "'Forgotten Undestanding' is a captivating painting by Shani Rahamim from the Perceptions album collection by the Dan Schachter Ensemble. Each artwork embodies a distinct melody, capturing the emotions and visuals Dan Schachter experienced during their creation. Released on Nov 22, immerse yourself in the full album on various platforms",
        links: [
          {
            label: "spotify",
            URL: "https://open.spotify.com/track/7u8i8S4q9t4vH9kQTN5pIE?si=9ffd510bec114886",
            caption: "listen to the song on spotify",
          },
        ],
      },
      {
        id: "2bf5fe3b-86d0-40ed-8e7c-c0c9bfd269ac",
        name: "Perceptions Album Cover",
        imageUrl:
          "https://mir-s3-cdn-cf.behance.net/project_modules/fs/c614a1188699749.659fe6242857c.jpg",
        description:
          "The official cover for the album Perceptions by the Dan Schachter Ensemble. holds easter eggs, can you spot all of them? \n" +
          '"Perceptions" Album Cover by Shani Rahamim: A Visual Journey Through Time and Memory\n' +
          "\n" +
          "Dive into the captivating world of the Dan Schachter Ensemble's \"Perceptions\" album, visually brought to life by artist Shani Rahamim. This cover art isn't just a visual treat; it's a puzzle, rich with hidden layers and easter eggs, inviting viewers to explore and interpret.\n" +
          "\n" +
          "A Mirror to the Soul: The Art of Reflection\n" +
          "Central to this masterpiece is an image of Dan Schachter, portrayed as an older man gazing into a mirror. The reflection, however, tells a different story – it shows him as a young man. This powerful contrast is more than mere imagery; it's a profound representation of the album's theme. It speaks to the nature of perceptions – how we see ourselves, how we evolve over time, and the fluidity of our memories and identity.\n" +
          "\n" +
          "Easter Eggs: A Challenge to the Observant Eye\n" +
          "Shani Rahamim's art is known for its layers and hidden elements. In the \"Perceptions\" cover, each easter egg is carefully placed, challenging the viewer to look beyond the obvious and discover the subtleties woven into the fabric of the artwork. These hidden gems are not just artistic flairs but are integral in conveying the album's narrative and thematic depth.\n" +
          "\n" +
          "Engage, Explore, and Experience\n" +
          'We invite you to take a closer look. Each element in this artwork has a story, a piece of a larger puzzle that forms the unique narrative of "Perceptions." How many easter eggs can you spot? What do they reveal to you about the album\'s essence?',

        links: [
          {
            label: "spotify",
            URL: "https://open.spotify.com/album/7qRgASyjO03ONAc8qF5fs5?si=iFbjTLAQS2CHNVbfFqWJjA",
            caption: "listen to the full album on spotify!",
          },
        ],
        products: [
          {
            URL: "https://www.redbubble.com/i/magnet/Perceptions-Album-Cover-by-shanirahamim/147587950.FRP2L?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
            name: "Perceptions Album Cover Magnet",
            imageURL:
              "https://ih1.redbubble.net/image.5070063531.7950/mo,large,fridge_close,tall_portrait,750x1000.2.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/acrylic-block/Perceptions-Album-Cover-by-shanirahamim/147587950.PB7KA?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
            name: "Perceptions Album Cover Acrylic Block",
            imageURL:
              "https://ih1.redbubble.net/image.5070063447.7950/abp,6x6,x750-pad,750x1000,f8f8f8.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/mouse-pad/Perceptions-Album-Cover-by-shanirahamim/147587950.NXCXM",
            name: "Perceptions Album Cover Mouse Pad",
            imageURL:
              "https://ih1.redbubble.net/image.5070063419.7950/ur,mouse_pad_medium_flatlay_prop,wide_portrait,750x1000.jpg",
          },
          {
            URL: "https://www.redbubble.com/i/coasters/Perceptions-Album-Cover-by-shanirahamim/147587950.E5I3N",
            name: "Perceptions Album Cover Coasters (Set of 4)",
            imageURL:
              "https://ih1.redbubble.net/image.5070063447.7950/ur,coaster_pack_4_flatlay,wide_portrait,750x1000.1.jpg",
          },
        ],
      },
    ],
  },
];

export const artWorks: Array<ArtWork> = [
  {
    id: "2bf5fe3b-86d0-40ed-8e7c-c0c9bfd269ab",
    name: "Morning Routine",
    imageUrl:
      "https://mir-s3-cdn-cf.behance.net/projects/max_808/0bd960173817255.Y3JvcCwzMDAwLDIzNDYsMCwzMjY.png",

    description:
      'The original Painting, from which Shani started "The Morning Routine Collection". The collection holds a total of 1600 items',
    links: [
      {
        URL: "https://opensea.io/collection/morning-routine-collection",
        label: "opensea",
        caption: "view the NFT collection",
      },
      {
        URL: "https://www.redbubble.com/people/shanirahamim/shop?artistUserName=shanirahamim&asc=u&collections=3579279&iaCode=u-prints",
        label: "redbubble",
        caption: "Shop Wall Art",
      },
    ],
    products: [
      {
        URL: "https://www.redbubble.com/i/throw-pillow/morning-routine-2-by-shanirahamim/147585712.5X2YF?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
        name: "morning routine 2 Throw Pillow",
        imageURL:
          "https://ih1.redbubble.net/image.5069982257.5712/throwpillow,small,750x1000-bg,f8f8f8.jpg",
      },
      {
        URL: "https://www.redbubble.com/i/framed-print/morning-routine-2-by-shanirahamim/147585712.L0LHG?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
        name: "morning routine 2 Framed Art Print",
        imageURL:
          "https://ih1.redbubble.net/image.5069982232.5712/cfp,large,black,off_white,box20,product,750x1000.jpg",
      },

      {
        name: "morning routine galaxy Throw Pillow",
        priceRange: "",
        URL: "https://www.redbubble.com/i/throw-pillow/morning-routine-galaxy-by-shanirahamim/148682933.5X2YF",
        imageURL:
          "https://ih1.redbubble.net/image.5108718942.2933/throwpillow,small,750x1000-bg,f8f8f8.webp",
      },
      {
        name: "Morning Routine Throw Pillow",
        priceRange: "9.99$ - 15.99$",
        URL: "https://ih1.redbubble.net/image.5108718942.2933/throwpillow,small,750x1000-bg,f8f8f8.webp",
        imageURL:
          "https://ih1.redbubble.net/image.5069891559.3213/throwpillow,small,750x1000-bg,f8f8f8.webp",
      },
      {
        name: "morning routine galaxy Coasters (Set of 4)",
        priceRange: "9.99$ - 15.99$",
        URL: "https://www.redbubble.com/i/coasters/morning-routine-galaxy-by-shanirahamim/148682933.E5I3N",
        imageURL:
          "https://ih1.redbubble.net/image.5108718930.2933/ur,coaster_pack_4_flatlay,wide_portrait,750x1000.1.webp",
      },
      {
        name: "Morning Routine Coasters (Set of 4)",
        priceRange: "",
        URL: "https://www.redbubble.com/i/coasters/Morning-Routine-by-shanirahamim/147583213.E5I3N",
        imageURL:
          "https://ih1.redbubble.net/image.5069891519.3213/ur,coaster_pack_4_flatlay,wide_portrait,750x1000.1.webp",
      },
      {
        URL: "https://www.redbubble.com/i/art-print/morning-routine-wave-white-by-shanirahamim/148682226.C3TL5",
        name: "Morning routine wave white Art Print",
        imageURL:
          "https://ih1.redbubble.net/image.5108689275.2226/carp,x_large,product,750x1000.jpg",
      },
      {
        URL: "https://www.redbubble.com/i/notebook/morning-routine-galaxy-by-shanirahamim/148682933.WX3NH",
        name: "morning routine galaxy Spiral Notebook",
        imageURL:
          "https://ih1.redbubble.net/image.5108718935.2933/sn,x1000-pad,750x1000,f8f8f8.jpg",
      },
    ],
  },
  {
    id: "2bf5fe3b-86d0-40ed-8e7c-c0c9bfd269av",
    name: "Autumnal Reverie",
    imageUrl:
      "https://mir-s3-cdn-cf.behance.net/project_modules/fs/3d5520188697855.659fe0db79146.png",
    description:
      "Introducing \"Autumnal Reverie,\" a mesmerizing painting that captures the essence of tranquility and the beauty of nature's cycles. This exquisite artwork features a woman, her hair aglow with a radiant, golden-yellow hue, reminiscent of the warm, inviting light of autumn. She lies peacefully amidst a bed of fallen leaves, a symbol of nature's gentle descent into the restful arms of winter. The artwork focuses solely on her face and head, enveloped by the colorful tapestry of leaves, creating an intimate and serene portrait.\n" +
      "\n" +
      "The woman's closed eyes and serene expression evoke a sense of deep contemplation and connection with nature. Her glowing hair not only adds a touch of surrealism to the piece but also symbolizes the light within us all, the inner peace and wisdom we find when we pause and connect with the natural world. The leaves around her, in a myriad of autumnal colors, represent the beauty of change, the elegance of life's impermanence, and the quiet dignity in letting go.\n" +
      "\n" +
      "\"Autumnal Reverie\" is not just a painting; it's a meditation, a visual representation of the soul finding solace in the simple, yet profound rhythms of nature. This artwork is perfect for anyone seeking a piece that brings a sense of calm, introspection, and a reminder of the beauty in life's transitions. It will transform any space into a haven of peace and reflection, making it an ideal addition to a living room, bedroom, or meditation space.\n" +
      "\n" +
      'Let "Autumnal Reverie" be a window to tranquility in your home, a daily invitation to pause, breathe, and appreciate the ever-changing tapestry of life. Its timeless elegance and profound symbolism make it an essential piece for any art lover seeking depth and serenity in their collection.',
  },
  {
    id: "2bf5fe3b-86d0-40ed-8e7c-c0c9bfd269ak",
    name: "Lama",
    imageUrl:
      "https://mir-s3-cdn-cf.behance.net/project_modules/fs/ff7e7c188697739.659fe08499fc4.jpg",
    description:
      "Shani writes- 'born during my return from Brazil straight into the embrace of a COVID quarantine. Immerse yourself in the vibrant hues of my imagination, where reality may differ, but the joy of soaring into the fantastical remains unparalleled. Join me on this artistic journey – where sweetness meets creativity!'",
  },
  {
    id: "2bf5fe3b-86d0-40ed-8e7c-c0c9bfd269alp",
    name: "Love At The Corner",
    imageUrl:
      "https://mir-s3-cdn-cf.behance.net/project_modules/fs/544ac4188698443.659fe286631c1.png",
    description: "Love at the street corner. Watch the process on instagram!",
  },
  {
    id: "2bf5fe3b-86d0-40ed-8e7c-c0c9bfd2689a",
    name: "Versioning",
    imageUrl:
      "https://mir-s3-cdn-cf.behance.net/project_modules/fs/3e6b7d188708531.659ffde8d5899.jpg",
    description:
      "We evolve with time\n" +
      "Each day, week, month, and year leads to a new version of us.\n" +
      "On each version upgrade, an older version of us stays behind",
    products: [
      {
        URL: "https://www.redbubble.com/i/clock/Versioning-by-shanirahamim/156401171.7PFC0?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
        name: "Versioning Clock",
        imageURL:
          "https://ih1.redbubble.net/image.5380935749.1171/clkc,white,white,1000x1250-pad,750x1000,f8f8f8.jpg",
      },
      {
        URL: "https://www.redbubble.com/i/framed-print/Versioning-by-shanrahamim/156401171.16RW4?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
        name: "Versioning Framed Art Print",
        imageURL:
          "https://ih1.redbubble.net/image.5380935736.1171/cfp,medium,black,off_white,box20,product,750x1000.webp",
      },
      {
        URL: "https://www.redbubble.com/i/metal-print/Versioning-by-shanirahamim/156401171.DD7AF?utm_source=rb-native-app&utm_campaign=share-product&utm_medium=android",
        name: "Versioning Metal Print",
        imageURL:
          "https://ih1.redbubble.net/image.5380935736.1171/cmp,large,gloss,tall_portrait,750x1000,study.jpg",
      },
    ],
  },
  {
    id: "8owwiofs",
    name: "Embrace of Elements",
    imageUrl:
      "https://mir-s3-cdn-cf.behance.net/project_modules/fs/c8e724189182765.65a7b9bdf07a5.png",
    description:
      //"What do you see in the picture? it describes a balance in nature. A balance that all of us know very well, which exists in every relationship. The feminine - masculine Balance. ts our nature, and we an be both ith different people ",
      'This captivating artwork, titled "Embrace of Elements," masterfully represents the timeless dance between the masculine and feminine forces of nature. At first glance, viewers are drawn into a serene yet powerful scene where a man, meticulously crafted from rugged, enduring stones, tenderly embraces a woman, whose form is seamlessly flowing as a river. This juxtaposition of elements - the solidity of rock against the fluidity of water - symbolizes the eternal balance that exists in every aspect of life and relationships.\n' +
      "\n" +
      "The man made of rocks stands as a metaphor for the masculine attributes: strength, stability, and protection. Meanwhile, the river woman epitomizes the feminine qualities: grace, adaptability, and nurturing flow. Together, they create a harmonious blend, reflecting the idea that we all embody both masculine and feminine energies, regardless of gender, and it is this balance that brings wholeness to our lives and relationships.\n" +
      "\n" +
      "This piece is not just an artwork; it's a philosophical exploration, inviting viewers to contemplate their own balance of energies. It's a reminder of the natural equilibrium we strive for in our daily interactions and personal connections. With its profound symbolism and exquisite craftsmanship, \"Embrace of Elements\" is more than a decorative piece; it's a conversation starter, a thought-provoking centerpiece for any living room, sparking introspection and discussion among all who view it.\n" +
      "\n" +
      "Whether you're seeking an artistic manifestation of inner balance or a stunning piece that speaks to the interconnectedness of all beings, this artwork is sure to captivate and inspire, making it an indispensable addition to any art collection.",
    products: [],
  },
  ...collections[0].artWorks,
];
