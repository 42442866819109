import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { artistUser } from "../data/data";
import styled from "styled-components";

const StyledAnchor = styled("a")(({ theme }) => ({
  textDecoration: "none",
  display: "inline-block",
  padding: "6px 16px", // Equivalent to theme.spacing(1) in default theme
  textAlign: "center",
  /*  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: theme.shape.borderRadius,*/
  /*  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },*/
}));

export default function Contact() {
  return (
    <main>
      {/* Hero unit */}
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Lets Connect;
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            {artistUser.contactMeDesc}
          </Typography>
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            {/*<Button variant="contained">
              <a target={"mailto:shanir.software.solutions@gmail.com"}>
                Send Me An Email
              </a>
            </Button>*/}
            <StyledAnchor
              href="mailto:shanir.software.solutions@gmail.com"
              target={"_blank"}
            >
              <Button variant="contained" component="span">
                Send Me An Email
              </Button>
            </StyledAnchor>
          </Stack>
        </Container>
      </Box>
    </main>
  );
}
