import * as React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { artistUser, artWorks } from "../data/data";
import { StyledDescriptionShort } from "../styled/StyledDescriptionShort";

//const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const cards = artWorks;

export default function Album() {
  return (
    <main>
      {/* Hero unit */}
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6,
        }}
      >
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Digital Art Works
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            {artistUser.galleryDesc}
          </Typography>
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <a
              href={
                artistUser.links.find(({ label }) => label === "redbubble")
                  ?.URL || ""
              }
              target="_blank"
              rel="noopener noreferrer"
              aria-label={"view"}
            >
              <Button variant="contained">Visit Shop</Button>
            </a>

            <Button variant="outlined" href="/contact">
              contact
            </Button>

            <a
              href="mailto:shanir.software.solutions@gmail.com"
              target={"_blank"}
              rel="noreferrer"
            >
              <Button variant="outlined" component="span">
                send an Email
              </Button>
            </a>
          </Stack>
        </Container>
      </Box>
      <Container sx={{ py: 8 }} maxWidth="md">
        {/* End hero unit */}
        <Grid container spacing={4}>
          {cards.map((card, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <CardMedia
                  component="div"
                  sx={{
                    // 16:9
                    pt: "56.25%",
                  }}
                  image={card.imageUrl}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {card.name}
                  </Typography>
                  <StyledDescriptionShort>
                    {card.description}
                  </StyledDescriptionShort>
                </CardContent>
                <CardActions>
                  <a
                    href={`/artwork/${card.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={"view"}
                  >
                    <Button size="small">View</Button>
                  </a>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </main>
  );
}
